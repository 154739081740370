/**
 * @description
 * serves as a bridge between {@link SettingsService} and {@link MainInterceptor}
 * reason: avoid circular dependency
 */
import {Injectable} from "@angular/core";
import {HttpRegionalSettings} from "../interfaces/general";
import {SharedAppSettings} from "../shared-settings/shared-settings";

@Injectable()
export class IntegrationService {

    get httpRegional(): HttpRegionalSettings {
        return this._httpRegional;
    }

    set httpRegional(value: HttpRegionalSettings) {
        this._httpRegional = value;
    }

    private _httpRegional: HttpRegionalSettings;

    constructor(private saSvc: SharedAppSettings) {

        this.httpRegional = {
            comAllowed: false,
            currencyId: this.saSvc.Defaults.CurrencyId,
            cultureId: this.saSvc.Defaults.CultureId
        };
    }

}