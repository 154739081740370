import {OnDestroy, OnInit} from "@angular/core";
import {DataService} from "../services/data.service";
import {SettingsService} from "../services/settings.service";
import {IPriceInfo, IProductAvail, IProductDiscount} from "../modules/product/common";
import {CredentialStorage} from "../services/credential-storage.service";
import {CatalogDisplayMode, SeoInfoSelector} from "../modules/category/interfaces";
import {ProductCarouselSource} from "../modules/product-carousel/common";
import {Itag} from "../modules/article-tag/common";
import {ProducerSelectorBrief} from "../modules/producer-filter/common";
import {ParameterSelector} from "../modules/product-parameters-filter/common";
import {Subject} from "rxjs";

declare let $: any;

/**
 * @description
 * A class for storing authorization data in localStorage
 * Can be instantiated via:
 * - new ai()
 * - ai.fromString()
 * */
export interface AuthInfo {
    realm: string;
    nonce: string;
    clientNonce: string;
    userName: string;
    displayName: string;
    loggedIn: boolean;
    validTo: string;
    a: number;
    b: number;
    c: number;
    hu: string;
    isB2B: boolean;
    isAdmin: boolean;
    companyId: number;
}

/**
 * @description
 * Interfaces for passing proper request values between function calls
 * Just to have one param instead of 3 or more
 */
export interface SettingItem {
    Key: string;
    Value: any;
}

export interface UserInfo {
    userDisplayName: string;
}

/**
 * @description
 * To have some structure of the emitted object to count with
 * Possible values for "type" are:
 * 'info'
 * 'error'
 */
export interface LoginEmitterMessage {
    type: string;
    message: string;
    status?: number;
    data?: any;
    routeToIndex?: boolean;
}

export interface CategoryItemSelector {
    id: number;
    parentId: number;
    level: number;
    displayName: string;
    idPath: string;
    seoUrl: string;
    imagePath: string;
    description: string;
    bcInfo: BcInfo[];
    articles: ArticleBriefSelector[];
    subcategories: CategoryItemSelector[];
    catSeoInfo: SeoInfoSelector;
}

/**
 * @description
 * Defines culture properties
 */
export interface Culture {
    code: string;
    name: string;
    cultureId: number;
    currencyId: number;
    currencyCode: string;
    currencySymbol: string;
    translationKey: string;
    htmlLangAttribute: string;
    cultureBoundDomain: string;
}

export interface Country {
    id: number;
    name: string;
}

export interface Currency {
    Id: number;
    Name: string;
    DisplayName: string;
    Code: string;
    RoundTo?: number;
    Enabled: boolean;
    IsMain: boolean;
}

export interface ArticleBriefSelector {
    articleId: number;
    name: string;
    title: string;
    seoUrl: string;
    parentArticle?: ArticleBriefSelector;
    childArticles?: ArticleBriefSelector[];
}

export interface ArticleMenuLinkSelector {
    articleId: number;
    articleName: string;
    articleUrl: string;
}

export interface ArticleMenuSectionSelector {
    sectionHeading: string;
    articleMenuLinks: ArticleMenuLinkSelector[];
    sectionId: number;
    sectionSeoUrl: string;
}

export interface ArticleSelector extends ArticleBriefSelector {
    anotation: string;
    body: string;
    extraData: string;
    dateCreated: any;
    dateLastUpdated?: any;
    seoKeywords: string;
    seoDescription: string;
    creatorUserName: string;
    lastUpdatedUserName: string;
    articleIDPath: string;
    imagePath: string;
    breadcrumbInfo?: BcInfo[],
    tags?: number[];
    transformedTags: Itag[];

    stringCreated?: string;
    stringLastUpdated?: any;
}

export interface ArticleSectionSelector extends ArticleSelector {
    checked: boolean;
}

export interface SearchRequest {
    phrase: string;
    pageSize: number;
    pageIndex: number;
    forSuggest: boolean;
    orderByKeys?: string[];
    categoryIds?: number[];
    parametricFilter?: ParametricFilterRequest;
}

export interface SearchSelector {
    categories: CategoryItemSelector[];
    products: IProductList[];
    totalProductCount: number;
    filteredProductCount: number;
    productsCategories?: CategoryItemSelector[];
    productsParams?: ParameterSelector[];
}

export type CartAdjustAction = 'ask' | 'merge' | 'keep' | 'replace';

/**
 * Collection of available validation patterns used in Settings interface
 */
export interface ValidationPatterns {
    email: RegExp;
    phone: IntRegExpDictionary;
    naturalNumber: RegExp;
    decimalNumber: RegExp;
    zipCodeCz: RegExp;
}

export interface DecimalSettings {
    ProductBox: number;
    Detail: number;
    Wishlist: number;
    Basket: number;
    Compare: number;
}

export interface IDateSettings {
    DatePattern: string;
    TimePattern: string;
    DateTimePattern: string;
}

/**
 * @description
 * Structure of the application settings which are stored in app/services/settings.service.ts.
 * This enables usage of settings via dot convention both in ts and html files.
 * If you add a setting to the json file, please add it to this interface as well.
 */
export interface Settings {
    preAuth: boolean;
    preAuthToLocal: boolean; // this makes the pre-authentication less agressive but less secure
    preAuthLocalExpiration: number;
    allowPermanentLogin: boolean;
    cultures: Array<Culture>;
    validationPatterns: ValidationPatterns;
    imageServerPathPrefix: string;
    countries: Country[];
    cartAdjustAction: CartAdjustAction;
    pageSizes: any[];
    localDomain: string;
    currentServerUrl: string;
    decimalSettings: DecimalSettings;
    dateSettings: {[key: number]: IDateSettings};
    smartsupp?: {[key: number]: string};
    assetPathPrefix: string;
    termsAndConditions: { [key: number]: TermsAndConditionsSelector }
}

export interface TermsAndConditionsSelector {
    termsUrl: string;
    gdprUrl: string;
    termsUrlB2C: string;
}
/**
 * @description
 * used to interchange info between settings service and auth.interceptor
 * for setting HttpHeaders
 */
export interface HttpRegionalSettings {
    cultureId: number;
    currencyId: number;
    comAllowed: boolean;
}

export interface StringIndexedObject {
    [key: string]: any;
}

export interface IntRegExpDictionary {
    [key: number]: RegExp;
}

/**
 * Base class for all components which use translations
 * translationPrefix is used to distinguish translations for particular routes (not to take them all from db)
 */
export abstract class Translatable implements OnInit, OnDestroy {

    unsubscribe: Subject<any> = new Subject<any>();
    sen: StringIndexedObject = {};
    protected _userLoggedIn: boolean;

    protected constructor(public dataSvc: DataService, public seSvc: SettingsService) {
        this.sen = this.seSvc.sen;
    }

    get userLoggedIn(): boolean {
        return CredentialStorage.userLoggedIn;
    }

    get isUserB2B(): boolean {
        return CredentialStorage.isUserB2B;
    }

    get isUserAdmin(): boolean {
        return CredentialStorage.isUserAdmin;
    }

    get isServerB2B(): boolean {
        return this.seSvc.domainInfo.b2b;
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public scrollUp() {
        $("html,body").animate({scrollTop: "0"});
    }

    get baseUrl(): string {
        return $('base')[0].href;

    }

    public svg(id: string): any {
        return this.seSvc.svg(id);
    }
}

export interface BcInfo {
    displayName: string;
    targetId?: number;
    targetSeoTail?: string;
    entityPrefix?: string;
}

export interface LoginResult {
    success: boolean;
    cultureId: number;
    currencyId: number;
    companyDisplayName: string;
    a: number;
    b: number;
    c: number;
    hu: string;
    isB2B: boolean;
    isAdmin: boolean;
    companyId: number;
}

export interface PreAuthRequest {
    userName: string;
    password: string;
    validTo: any;
}

export interface PreAuthResponseModel {
    success: boolean;
}

export interface CategoryInfo {
    id: number;
    name: string;
    seo: string;
}

export function mapPrices(thePrices: IPriceInfo, theProduct: IProductList) {

    theProduct.PriceWithVAT = thePrices.PriceWithVAT;
    theProduct.PriceWithoutVAT = thePrices.PriceWithoutVAT;
    theProduct.BasePrice = thePrices.BasePrice;
}

export interface IProductList {
    Id: number;
    Anotation: string;
    Description: string;
    Code: string;
    CodeDisplay: string;
    Ean: string;
    Avail?: number;
    Name: string;
    BasePrice?: number;
    BasePriceWVAT?: number;
    BasePriceForDiscount?: number;
    PriceWithVAT?: number;
    PriceWithoutVAT?: number;
    Vat: number;
    Discount?: number;
    MaxActionDiscount?: number;
    MaxSelloutDiscount?: number;
    ProducerId: number;
    ProducerName?: string;
    SeoUrl?: string;
    ImagePath?: string;
    DefaultPriceWithVAT?: number;
    DefaultPriceWithoutVAT?: number;
    DefaultPricelistPriceWithoutVAT?: number;
    DefaultPricelistPriceWithVAT?: number;
    SalePriceWithoutVAT?: number;
    SalePriceWithVAT?: number;
    DefaultSalePriceWithoutVAT?: number;
    DefaultSalePriceWithVAT?: number;

    DefaultPricelistPriceForDiscount?: number;
    DefaultPricelistPriceForDiscountWithoutVAT?: number;
    DefaultPricelistSalePriceForDiscount?: number;
    DefaultPricelistSalePriceForDiscountWithoutVAT?: number;


    HasBasePriceList: boolean;
    IsAction?: boolean;
    IsNew?: boolean;
    IsSellOut?: boolean;
    IsDiscount?: boolean;
    IsIndividualDelivery?: boolean;
    IsFixedPrice?: boolean;
    IsDangerGoods?: boolean;
    IsContract?: boolean;
    IsOrder?: boolean;

    IsDifferentPriceListThanDefault?: boolean;

    Unit?: string;
    Order?: number | string;
    BreadcrumbInfo: BcInfo[];
    Currency: Currency;
    NearestCategory?: CategoryInfo;
    Ranking: number;
    MinQtyOrder: number;
    PackageQty?: number;
    SetCount: number;
    IsDeliveryFree: boolean;
    IsGift: boolean;
    Dimension: string;

    Producer?: ProducerSelectorBrief;
    CategoriesBreadcrumbs: BcInfo[][];

    VariantCount: number;
    MasterInfo: BcInfo;
    PriceFromWithVAT?: number;
    PriceFromWithoutVAT?: number;
    AvailData?: IProductAvail;

    Discounts: IProductDiscount[];
}

export interface IQuickOrderProduct extends IProductList {
    Count?: number;
}

export interface IQuickOrderResult {
    validItems: IQuickOrderProduct[];
    noValidItems: string[];
    notifications: string[];
}

export interface IWishList {
    id: number;
    name: string;
    hash: string;
    lastModified: string;
    productIds: number[];
    products: IProductList[];
}

/**
 * @description Represents a range of values for the comparison 'the value is >= and <='.
 */
export class ValueRangeParameter {
    get _toValue(): number {
        return this.toValue;
    }

    get _id(): number {
        return this.id;
    }

    get _fromValue(): number {
        return this.fromValue;
    }

    constructor(private id: number, private fromValue: number, private toValue: number) {
    }
}

/**
 * @description Represents a parameter value for the equality comparison of product parameters.
 */
export class ExactValueParameter {
    get _id(): number {
        return this.id;
    }

    get _valueId(): number {
        return this.valueId;
    }

    constructor(private id: number, private valueId: number) {
    }
}

/**
 * @description Represents the filter according to parameter values of the products.
 */
export class ParametricFilterRequest {
    private exactValueParameters: ExactValueParameter[];
    private valueRangeParameters: ValueRangeParameter[];

    constructor() {
        this.exactValueParameters = [];
        this.valueRangeParameters = [];
    }

    /**
     * @description Adds a condition for the equality comparison to the parametric filter.
     */
    addExactValue(id: number, valueId: number) {
        let exactValue = new ExactValueParameter(id, valueId);
        this.exactValueParameters.push(exactValue);
    }

    /**
     * @description Adds a condition for the comparison 'the value is >= and <=' to the parametric filter.
     */
    addValueRange(id: number, fromValue: number, toValue: number) {
        let range = new ValueRangeParameter(id, fromValue, toValue);
        this.valueRangeParameters.push(range);
    }

    /**
     * @description Clears all conditions for the parametric filter and sets state 'no filtering'.
     */
    clear(): void {
        this.valueRangeParameters.splice(0);
        this.exactValueParameters.splice(0);
    }

    getExactValues(): ExactValueParameter[] {
        return this.exactValueParameters;
    }

    isExactChecked(id: number, valueId: number): boolean {
        return this.exactValueParameters.some(
            param => param._id === id && param._valueId === valueId
        );
    }
}

export interface ErrorSelector {
    message: string;
    subject?: string;
}

export interface PagedResponse<T> {
    total: number;
    data: T[];
}

export interface ProdAttrConfig extends StringIndexedObject {
    attribute: ProductCarouselSource;
    cultureId?: number;
    pageSizeIndex?: number;
    pageIndex?: number;
    displayMode?: CatalogDisplayMode;
    orderByIndex: number;
}

export interface IProductPriceRequest {
    productId: number;
    qty: number;
}

export interface IProductPriceResponse {
    productId?: number;
    priceWithVat?: number;
    priceWithoutVat?: number;
    discount?: number;
}

export interface IGAEventFields {
    hitType: string;
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    eventValue?: number;
}

export interface IDomainInfo {
    b2b: boolean;
    domain: string;
    name: string;
    otherDomain: string;
    domainCode: string;
    cultureId: number;
    currencyId: number;
}
export interface IAresDetail {
    RegNo: string;
    Dic: string;
    Error: IAresError;
    BusinessName: string;
    Address: IAresAddress;
}

export interface IAresAddress {
    Source: TAresAddressSource;
    Valid: boolean;
    Street?: string;
    StreetWithNumber?: string;
    StreetNumber?: string;
    HouseNumber?: number;
    City?: string;
    CityPart?: string;
    ZipCode?: string;
    Country?: string;
    CountryId?: number;
    CurrencyId?: number;
}

export interface IAresError {
    Text: string;
    Code: number;
}

export type TAresAddressSource = 'ARES' | 'VIES';


export interface GaSelector {
    analyticsId: string;
    adsId: string;
    adsLabel: string;
    remarkId: string;
    gtmId: string;
}

export interface FbSelector {
    applicationId: string;
    applicationToken: string;
}

export interface SeznamSelector {
    sklikConversionId: string;
    sklikRetargetingId: string;
    zboziConversionBranchId: string;
}

export interface HeurekaSelector {
    publicKey: string;
}

export interface IUrl {
    schema: string;
    domain: string;
    path: string;
    query: string;
    hash: string;
}