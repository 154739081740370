import {EventEmitter, Injectable} from "@angular/core";
import {
    loadFromLocalStorage,
    loadFromSession,
    removeFromLocalStorage,
    removeFromSession,
    saveToLocalStorage,
    saveToSession
} from "../../helpers/cookie.helper";
import {DataService} from "../../services/data.service";
import {delay, finalize, take, takeUntil} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {
    AddDeliveryPaymentRequest,
    BranchServices,
    CartCheckRequest,
    CartCheckSelector,
    CartDialogConfig,
    CartDialogMultiConfig,
    CartItemSelectorBrief,
    CartItemSelectorFull,
    CartSelector,
    Delivery,
    DeliveryPayment,
    ErrorType,
    InvalidProduct,
    IOrderToCart
} from "./common";
import {CartTokenService} from "./cart-token.service";
import {AddressSelector, CompanySelector, InvoiceAddressSelector, UserSelector} from "../address/common";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {SettingsService} from "../../services/settings.service";
import {GeneralDialogService} from "../general-dialog/general-dialog.service";
import {AlertModalType, IAlertModalConfig} from "../alert-modal/alert-modal/common";
import {AlertModalComponent} from "../alert-modal/alert-modal/alert-modal.component";
import {CartChangeModalComponent} from "../cart-modal/cart-change-modal.component";
import {GeneralDialogRef} from "../general-dialog/common";
import {CartChangeModalListComponent} from "../cart-modal/cart-change-modal-list.components";

@Injectable()
export class CartService {

    getContentDelayMillisesonds: number = 500;

    orderHash: string;

    cartContentChanged: EventEmitter<CartSelector>;
    cartItemCountChanged: EventEmitter<CartItemSelectorBrief>;
    cartItemsCountAdded: EventEmitter<CartDialogMultiConfig>;
    cartEmptied: EventEmitter<any>;

    public isNextStep4: boolean = false;

    private ngUnsubscribe_cartContent: Subject<any> = new Subject();
    private ngUnsubscribe_removeFromCart: Subject<any> = new Subject();
    private ngUnsubscribe_addToCart: Subject<any> = new Subject();
    private ngUnsubscribe_changeCount: Subject<any> = new Subject();
    private ngUnsubscribe_addCouponToCart: Subject<any> = new Subject();
    private ngUnsubscribe_setDeliveryAndPayment: Subject<any> = new Subject();
    private ngUnsubscribe_emptyCart: Subject<any> = new Subject();
    private ngUnsubscribe_addProductsByCodes: Subject<any> = new Subject();

    private invoiceAddressKey: string = 'cart.invoiceAddressKey';
    private deliveryAddressKey: string = 'cart.deliveryAddressKey';
    private companyKey: string = 'cart.companyKey';
    private withCompanyKey: string = 'cart.withCompanyKey';
    private userKey: string = 'cart.userCompanyKey';
    private withDeliveryAddressKey: string = 'cart.withDeliveryAddressKey';
    private withDifferentAddressKey: string = 'cart.withDifferentAddressKey';
    private parcelShopBranchKey: string = 'cart.parcelShopBranchKey';
    private deliveryChangedKey: string = 'cart.deliveryChangedKey';
    private customerOrderNumberKey: string = 'cart.customerOrderNumberKey';
    private descriptionKey: string = 'cart.descriptionKey';
   // private paymentChangeKey: string = 'cart.paymentChangedKey';
  //  private giftChangedKey: string = 'cart.giftChangedKey';
    private addDialogRef: GeneralDialogRef;

    get token(): string {
        return this.cartTokenSvc.getCartToken();
    }

    set token(val: string) {
        this.cartTokenSvc.saveCartToken(val);
    }

    public countChangedByOrder: boolean = false;

    private _cart: CartSelector;
    set cart(value: CartSelector) {

        if (value) {
            let company = loadFromSession(this.companyKey);
            if (company) {
                value.company = company;
            }

            let withCompany = loadFromSession(this.withCompanyKey);
            value.withCompany = withCompany;

            let withDeliveryAddress = loadFromSession(this.withDeliveryAddressKey);
            value.withDeliveryAddress = withDeliveryAddress;

            let withDifferentAddress = loadFromSession(this.withDifferentAddressKey);
            value.withDifferentAddress = withDifferentAddress;

            let user = loadFromSession(this.userKey);
            if (user) {
                value.user = user;
            }

            let invoiceAddress = loadFromSession(this.invoiceAddressKey);
            if (invoiceAddress) {
                value.invoiceAddress = invoiceAddress;
            }

            let deliveryAddress = loadFromSession(this.deliveryAddressKey);
            if (deliveryAddress) {
                value.deliveryAddress = deliveryAddress;
            }
        }


        this._cart = value;
    }

    get cart(): CartSelector {
        return this._cart;
    }


    constructor(private dataSvc: DataService, private http: HttpClient, public cartTokenSvc: CartTokenService, public seSvc: SettingsService, public dialogSvc: GeneralDialogService) {
        this.cartContentChanged = new EventEmitter();
        this.cartItemCountChanged = new EventEmitter<CartItemSelectorBrief>();
        this.cartEmptied = new EventEmitter();

        this.cartItemsCountAdded = new EventEmitter<CartDialogMultiConfig>();

        this.cart = CartService.getOrderSessionData(true);

        this.getCartContentForToken();
        this.initiateModalDialogHook();
    }

    get productList(): CartItemSelectorFull[] {
        if (this.cart && this.cart.cartContent) {
            return this.cart.cartContent.filter(cc => cc.productDetail);
        }
        return null;
    }

    get giftList(): CartItemSelectorFull[] {
        if (this.cart && this.cart.cartContent) {
            return this.cart.cartContent.filter(cc => cc.GiftProductDetail);
        }
        return null;
    }



    get coupons(): CartItemSelectorFull[] {
        if (this.cart && this.cart.cartContent) {
            return this.cart.cartContent.filter(cc => cc.couponDetail);
        }
        return null;
    }

    private _generalPendingOperation: number = 0;
    set generalPendingOperation(value: boolean) {
        if (value) {
            this._generalPendingOperation++;
        }
        else {
            this._generalPendingOperation--;
        }
    }

    get generalPendingOperation(): boolean {
        return this._generalPendingOperation > 0;
    }


    getCartStatus(): any {
        let url = `api/cart/getCartStatus`;
        return this.http.get(url);
    }

    performChecks(withPromotions: boolean, withCompare: boolean): Observable<CartCheckSelector> {
        const url = 'api/cart/performChecks';
        const data: CartCheckRequest = {
            withPromotions: withPromotions,
            withCompare: withCompare,
            clientCart: this.cart
        };
        return this.http.post<CartCheckSelector>(url, data)
            .pipe(take(1))
    }

    performFullCheck(): Observable<CartCheckSelector> {
        return this.performChecks(true, true)
    }

    addToCart(cart: CartItemSelectorBrief): void {

        this.dataSvc.dataLoading = true;
        let url = `api/cart/addToCart`;

        cart.cartToken = this.token;
        cart.fromCart = false;

        this.http.post(url, cart)
            .pipe(takeUntil(this.ngUnsubscribe_addToCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (res: CartSelector) => {
                    this.token = res.cartToken;
                    this.cart = res;
                    this.cartContentChanged.emit(res);

                    let full = this.productList.find(p => p.productId == cart.productId);
                    // let full = [...this.productList, ...this.cart.notToOrderCartContent || []].find(p => p.productId == cart.productId);
                    full.disableMessage = true;//cart.disableMessage;
                    this.cartItemCountChanged.emit(full);
                }, () => {

                });
    }

    addGiftToCart(cart: CartItemSelectorBrief): void {

        this.dataSvc.dataLoading = true;
        let url = `api/cart/addGiftToCart`;

        cart.cartToken = this.token;
        cart.fromCart = false;

        this.http.post(url, cart)
            .pipe(takeUntil(this.ngUnsubscribe_addToCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (res: CartSelector) => {
                    this.token = res.cartToken;
                    this.cart = res;
                    this.cartContentChanged.emit(res);

                }, () => {

                });
    }
    // getCartContentForUser(): any {
    //     let url = 'api/cart/getCartContentForUser' + (this.token ? '/' + this.token : '');
    //     return this.http.get(url)
    // }

    getCartContentForToken(): void {
        let url = 'api/cart/getCartContentForToken';
        this.http.get<CartSelector>(url)
            .pipe(takeUntil(this.ngUnsubscribe_cartContent))
            .pipe(delay(this.getContentDelayMillisesonds))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe((res: CartSelector) => {
                this.cart = res;
                this.cartContentChanged.emit(res);
            });
    }

    broadcastCartEmptied(): void {
        this.cartEmptied.emit()
    }

    removeFromCart(cart: CartItemSelectorBrief): void {
        this.dataSvc.dataLoading = true;

        let url = `api/cart/removeFromCart`;

        this.http.post(url, cart)
            .pipe(takeUntil(this.ngUnsubscribe_removeFromCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                () => {
                    this.getCartContentForToken();
                },
                () => {
                }
            );
    }

    changeCount(cart: CartItemSelectorBrief): void {

        this.dataSvc.dataLoading = true;
        cart.cartToken = this.token;

        let url = `api/cart/changeCount`;

        this.http.post(url, cart)
            .pipe(takeUntil(this.ngUnsubscribe_changeCount))
            .pipe(
                finalize(() => {
                    this.dataSvc.dataLoading = false;
                })
            )
            .subscribe((res: CartSelector) => {
                this.cart = res;
                let changedItem = this.cart.cartContent.find(item => item.productId == cart.productId);
                if (changedItem) {
                    changedItem.fromCart = cart.fromCart;
                    changedItem.disableMessage = true;//cart.disableMessage;
                    this.cartItemCountChanged.emit(changedItem);
                }
                this.cartContentChanged.emit(res);
            }, () => {

            });

    }

    changeProductCount(productId: number, delta: number): void {
        delta = parseFloat(delta.toString());
        let cartItem = this.cart.cartContent.find(cc => cc.productDetail && cc.productDetail.Id == productId);
        if (!cartItem) {
            return;
        }
        let cart: CartItemSelectorBrief = {
            count: cartItem.count + delta,
            id: cartItem.id,
            fromCart: true
        };
        this.changeCount(cart);
    }

    getCouponFromServer(code: string): any {
        let url = `api/coupon/${code}`;
        return this.http.get(url)
    }

    getGifts(): any {
        let url = `api/gift`;
        return this.http.get(url)
    }
    addCouponToCart(couponCode: string, fromCart: boolean = false): Observable<any> {
        let url = `api/cart/addCouponToCart`;
        const data = {
            couponCode: couponCode,
            Token: this.token,
            FromCart: fromCart
        };

        let sub = new Subject<CartSelector>();

        this.http.post(url, data)
            .pipe(takeUntil(this.ngUnsubscribe_addCouponToCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe((res: CartSelector) => {
                if (res) {
                    this.cart = res;
                    this.couponChanged();
                }
                sub.next(res);
            });


        return sub;
    }

    addDeliveryAndPayment(deliveryAndPayment: DeliveryPayment): void {
        let url = "api/cart/add-delivery-and-payment";

        if(!deliveryAndPayment || !deliveryAndPayment.Payment ||
            (!deliveryAndPayment.Delivery &&
             !deliveryAndPayment.DangerousGoods &&
             !deliveryAndPayment.IndividualDelivery)) {

            return;
        }

        let request: AddDeliveryPaymentRequest = {
            deliveryId: deliveryAndPayment.Delivery ? deliveryAndPayment.Delivery.Id : null,
            paymentId: deliveryAndPayment.Payment.id
        };

        this.dataSvc.dataLoading = true;
        this.http.post(url, request)
            .pipe(takeUntil(this.ngUnsubscribe_setDeliveryAndPayment))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe((res: CartSelector) => {
                if (res) {
                    this.cart = res;
                }
            }, () => {
            });
    }

    couponChanged(): void {
        this.cartContentChanged.emit();
    }


    public static getOrderSessionData(emptyIfNull?: boolean): CartSelector {
        let ss: CartSelector = <CartSelector>loadFromLocalStorage("order");
        if (!ss && emptyIfNull) {
            ss = {
                cartActionResult: null,
                cartContent: null,
                notToOrderCartContent: null,
                delivery: null,
                payment: null,
                priceTotalWithVat: null,
                priceTotalWithOutVat: null,
                priceWithoutPaymentAndDeliveryWithVat: null,
                priceWithoutPaymentAndDeliveryWithOutVat: null,
                itemsCount: null,
                deliveryFreeFrom: null,
                errorMessage_SenKey: null,
                cartToken: null,
                fromCart: null,
                invoiceAddress: null,
                deliveryAddress: null,
                company: null,
                user: null,
                customerOrderNumber: null,
                //former Additional:

                logToNewsletter: false,
                maxStep: 1,
                withCompany: false,
                note: '',
                otherAddress: '',
                withDeliveryAddress: false,
                withDifferentAddress: false,
                register: false
            };
        }
        return ss;
    }

    public storeOrderSessionData(): void {
        saveToLocalStorage("order", this.cart);
    }

    public emptyCart(): void {
        let url = 'api/cart/empty-cart';
        this.http.get(url)
            .pipe(takeUntil(this.ngUnsubscribe_emptyCart))
            .subscribe((res) => {
                if (res) {
                    removeFromLocalStorage('order');
                    //this.cart = null;
                    this.resetCart();
                    this.cartContentChanged.emit(null);
                    this.cartEmptied.emit();
                }
            });
    }

    public emptyCartNextStep(): void {
        this.ngUnsubscribe_cartContent.next();
        this.isNextStep4 = true;
        let url = 'api/cart/empty-cart';
        this.http.get(url)
            .pipe(takeUntil(this.ngUnsubscribe_emptyCart))
            .subscribe((res) => {
                if (res) {
                    removeFromLocalStorage('order');
                    //this.cart = null;
                    this.resetCart();
                    this.cartContentChanged.emit(this.cart);
                    this.cartEmptied.emit();
                }
            });
    }
    public resetCart():void{
        this.cart = {
            cartActionResult: null,
            cartContent: null,
            notToOrderCartContent: null,
            delivery: null,
            payment: null,
            priceTotalWithVat: null,
            priceTotalWithOutVat: null,
            priceWithoutPaymentAndDeliveryWithVat: null,
            priceWithoutPaymentAndDeliveryWithOutVat: null,
            itemsCount: null,
            deliveryFreeFrom: null,
            errorMessage_SenKey: null,
            cartToken: null,
            fromCart: null,
            invoiceAddress: null,
            deliveryAddress: null,
            company: null,
            user: null,
            customerOrderNumber: null,
            logToNewsletter: null,
            maxStep: null,
            withCompany: null,
            note: null,
            otherAddress: null,
            withDeliveryAddress: null,
            withDifferentAddress: null,
            register: false
        }
    }

    public sendOrder(): any {

        this.onGetParcelShop();
        let url = 'api/order/save';
        return this.http.post(url, this.cart);
    }

    //giftChangedKey
  /*  onGiftChangeInBasket(gift: IGift){

    }*/
    onParcelShopChanged(branch: BranchServices) {
        this.cart.branchInfo = branch;
        saveToSession(this.parcelShopBranchKey, this.cart.branchInfo);
    }
    onGetParcelShop(){
        this.cart.branchInfo = loadFromSession(this.parcelShopBranchKey);
    }
    onDeliveryChanged(delivery: Delivery){
        this.cart.delivery = delivery;
        saveToSession(this.deliveryChangedKey, delivery);
    }
    onInvoiceAddressChanged(address: InvoiceAddressSelector) {
        this.cart.invoiceAddress = address;
        saveToSession(this.invoiceAddressKey, this.cart.invoiceAddress);
    }

    onDeliveryAddressChanged(address: AddressSelector) {
        this.cart.deliveryAddress = address;
        saveToSession(this.deliveryAddressKey, this.cart.deliveryAddress);
    }

    onCompanyChanged(company: CompanySelector) {
        this.cart.company = company;
        saveToSession(this.companyKey, this.cart.company);
    }

    onWithDeliveryAddressChanged(withDeliveryAddress: boolean) {
        this.cart.withDeliveryAddress = withDeliveryAddress;
        saveToSession(this.withDeliveryAddressKey, this.cart.withDeliveryAddress);
    }
    onWithDifferentAddressChanged(withDifferentAddress: boolean) {
        this.cart.withDifferentAddress = withDifferentAddress;
        saveToSession(this.withDifferentAddressKey, this.cart.withDifferentAddress);
    }
    onWithCompanyChanged(withCompany: boolean) {
        this.cart.withCompany = withCompany;
        saveToSession(this.withCompanyKey, this.cart.withCompany);
    }

    onUserChanged(user: UserSelector) {
        this.cart.user = user;
        saveToSession(this.userKey, this.cart.user);
    }

    onCustomerOrderNumberChanged(customerOrderNumber: string) {
        this.cart.customerOrderNumber = customerOrderNumber;
        saveToSession(this.customerOrderNumberKey, this.cart.customerOrderNumber);
    }

    onDescriptionChanged(description: string) {
        this.cart.note = description;
        saveToSession(this.descriptionKey, this.cart.note);
    }

    cleanCartOnLogout() {

        this.cartTokenSvc.saveCartToken(null);
        removeFromSession(this.invoiceAddressKey);
        removeFromSession(this.deliveryAddressKey);
        removeFromSession(this.companyKey);
        removeFromSession(this.withDeliveryAddressKey);
        removeFromSession(this.withDifferentAddressKey);
        removeFromSession(this.withCompanyKey);
        removeFromSession(this.userKey);
        removeFromSession(this.descriptionKey);
        removeFromSession(this.customerOrderNumberKey);
    }

    get personalPickup(): boolean {
        // if (this.cart && this.cart.cartContent) {
        //     return !!this.cart.cartContent.find(q => q.productDetail.OnlyPersonally);
        // }
        return false;
    }

    addProductsByCodes(productCodes: string): void {
        let url = `api/cart/quickPurchase`;
        this.dataSvc.dataLoading = true;
        this.http.post(url, productCodes)
            .pipe(takeUntil(this.ngUnsubscribe_addProductsByCodes))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe((res: CartSelector) => {
                if (res) {
                    this.cart = res;
                    this.cartContentChanged.emit(res);
                }
            }, () => {
            });
    }

    addOrderToCart(orderNumber: number): void {
        let url = `api/cart/order-to-cart`;
        let req: IOrderToCart ={orderId: orderNumber};
        this.dataSvc.dataLoading = true;
        this.http.post(url, req)
            .pipe(takeUntil(this.ngUnsubscribe_addProductsByCodes))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe((res: CartSelector) => {
                if (res) {
                    this.cart = res;
                    this.cartContentChanged.emit(res);
                }
            }, () => {
            });
    }

    alertPriceChanged(): Observable<any> {
        return this.showAlert(this.seSvc.sen['alert-price-changed'], 'warning');
    }

    alertDangerGoodsOrIndividualDelivery(): Observable<boolean> {
        return this.showAlert(this.seSvc.sen['alert-danger-or-individual'], 'warning')
    }

    alertRegistrationDisabled(): Observable<any> {
        return this.showAlert('Pokud jste měli aktivní účet na starého eshopu, převedli jsme jej a pro snazší přihlášení jsme vám zaslali aktivační email (z adresy eshop@okentes.cz).<br>' +
            'Pokud jste aktivační email neobdrželi, a nedaří se vám zaregistrovat, kontaktujte nás na admin@okentes.cz', 'warning');
    }

    alertForgottenPassword(): Observable<any> {
        return this.showAlert('POZOR:<br>' +
            'Zapomenuté heslo funguje <strong>pouze u účtů</strong>, které již byly <strong>na novém webu aktivovány</strong>.' +
            '<br> Na používané účty z našeho původním eshopu jsme zasílali z adresy eshop@okentes.cz aktivační email přes který lze účet snadno aktivovat.<br>' +
            'Pokud jste aktivační email neobdrželi, a nedaří se vám ani zaregistrovat, kontaktujte nás na admin@okentes.cz', 'warning');
    }

    protected showAlert(text: string, type: AlertModalType) {
        const config: GeneralDialogConfig<IAlertModalConfig> = {
            data: {text: text, type: type},
            title: null,
            cssClassModifier: 'alert',
            isCloseAble: true
        };
        let dialog = this.dialogSvc.open(AlertModalComponent, config);
        return dialog.afterClosed
    }

    addItemsToCart(inputs: CartItemSelectorBrief[], noValid?: string[]): void {

        this.dataSvc.dataLoading = true;
        let url = `api/cart/add-to-cart-multi`;

        let token = this.token;
        let items = inputs.map((x) => {
            x.cartToken = token;
            x.fromCart = false;
            return x;
        });

        //ecomail:
        // if(this.cookieConsentSvc.isAdsConsent()){
        //     url+="?isAddsConsent=1"
        //     const ecmid = loadFromSession("ecmid");
        //     if (ecmid) {
        //         url += "&ecmid=" + ecmid;
        //     }
        // }

        this.http.post(url, items)
            .pipe(takeUntil(this.ngUnsubscribe_addToCart))
            .pipe(finalize(() => {
                this.dataSvc.dataLoading = false;
            }))
            .subscribe(
                (res: CartSelector) => {
                    if (res){
                        this.token = res.cartToken;
                        this.cart = res;
                        this.cartContentChanged.emit(res);

                        let fulls: CartItemSelectorFull[] = [];
                        if (this.cart && this.cart.cartContent && this.cart.cartContent.length > 0) {
                            fulls = inputs.map((x) => {
                                let full = <CartItemSelectorFull>x;
                                let prod = this.productList.find(p => p.productId == x.productId);
                                if (prod) {
                                    full.productDetail = prod.productDetail;
                                    full.count = full.productDetail.MinQtyOrder == 0 || full.count % full.productDetail.MinQtyOrder == 0 ? full.count : full.productDetail.MinQtyOrder;
                                }
                                full.disableMessage = true;
                                return full;
                            });

                            // let gtmItems = fulls.map(x => {
                            //     let full = this.productList.find(p => p.productId == x.productId);
                            //     return new GtmCartProduct(full, x.count);
                            // });
                            // this.gtmSvc.addToCart(gtmItems);
                        }
                        if (!res.invalidProducts) {
                            res.invalidProducts = [];
                        }
                        noValid.forEach(code => {
                                res.invalidProducts.push({
                                    Product: null,
                                    ErrorType: ErrorType.Product,
                                    ProductCode: code
                                });
                        });

                        this.storeOrderSessionData();
                        const emitData: CartDialogMultiConfig = {
                            validItems: fulls
                                .filter(x => x.productDetail && !res.invalidProducts.some(invalid => invalid.ProductCode === x.productDetail.CodeDisplay))
                                .map(x => ({
                                    cartItem: x
                                })),
                            invalidProducts: res.invalidProducts
                        };

                        this.cartItemsCountAdded.emit(emitData);
                    } else {
                        const emitData: CartDialogMultiConfig = {
                            validItems: null,
                            invalidProducts: noValid.map(p => ({
                                ProductCode: p,ErrorType: ErrorType.Product,Product: null
                            }))
                        };
                        this.cartItemsCountAdded.emit(emitData);
                    }

                });
    }

    private initiateModalDialogHook() {
        this.cartItemCountChanged
            .subscribe((res: CartItemSelectorBrief) => {

                if (res && !res.fromCart && !res.disableMessage) {
                    let cartItem = [...this.productList, ...this.cart.notToOrderCartContent || []].find(p => p.id == res.id);
                    this.showCartAddDialog(cartItem);
                }
            });

        this.cartItemsCountAdded
            .subscribe((res: CartDialogMultiConfig) => {
                this.showCartAddListDialog(res);
            });
    }

    get notToOrderProducts(): CartItemSelectorFull[] {
        if (this.cart.notToOrderCartContent) {
            return this.cart.notToOrderCartContent.filter(cc => cc.productDetail);
        }
        return [];
    }

    private showCartAddDialog(cartItem: CartItemSelectorFull): void {
        const data: CartDialogConfig = {cartItem: cartItem};
        const config: GeneralDialogConfig<CartDialogConfig> = {
            data: data,
            cssClassModifier: 'product-buy',
            isCloseAble: true,
            title: null
        };
        if (this.addDialogRef) this.addDialogRef.close();
        this.addDialogRef = this.dialogSvc.open(CartChangeModalComponent, config);
        this.addDialogRef.afterClosed
            .subscribe(() => {
            });
    }

    private showCartAddListDialog(data: CartDialogMultiConfig): void {
        const config: GeneralDialogConfig<CartDialogMultiConfig> = {
            data: data,
            cssClassModifier: 'product-buy',
            isCloseAble: true,
            title: null
        };
        const dialogRef = this.dialogSvc.open(CartChangeModalListComponent, config);
        dialogRef.afterClosed
            .subscribe((res: InvalidProduct[]) => {
                if (res) {
                    let textArea = <HTMLInputElement>document.getElementById('batchForm');
                    let products = res.filter(p => p.Product !== null);
                    if (products){
                        products.forEach(p => {
                            if (textArea.value == "") textArea.value = p.ProductCode + ';' + p.Count;
                            else textArea.value = textArea.value + '\n' + p.ProductCode + ';' + p.Count;
                        })
                    }
                }
            });
    }

}
