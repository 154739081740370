import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Translatable} from "../../../interfaces/general";
import {SettingsService} from "../../../services/settings.service";
import {DataService} from "../../../services/data.service";
import {GeneralDialogConfig} from "../../general-dialog/general-dialog-config";
import {GeneralDialogRef} from "../../general-dialog/common";
import {AlertModalType, IAlertModalConfig} from "./common";

@Component({
    selector: 'cmp-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ["../../../../assets/styles/2-components/modal-window/modal-alert.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AlertModalComponent extends Translatable implements OnInit {

    @Input() mode: AlertModalType;
    @Input() title: string;
    @Input() text: string;

    constructor(seSvc: SettingsService,
                dataSvc: DataService,
                private config: GeneralDialogConfig<IAlertModalConfig>,
                private dialogRef: GeneralDialogRef) {
        super(dataSvc, seSvc);

        if (this.config && this.config.data) {
            this.title = this.config.data.title;
            this.mode = this.config.data.type;
            this.text = this.config.data.text;
        }
    }


    ngOnInit(): void {
    }

    public Close() {
        this.dialogRef.close(true);
    }
}
