/**
 * Main application module
 * WARNING:
 * Since this module is loaded first, keep it as small as possible!!!
 * do not add any route-specific features here, look into "app/modules/app/app.routes.ts"
 * how this should be done correctly
 */
import {NgModule, enableProdMode, APP_INITIALIZER, LOCALE_ID, ErrorHandler} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppComponent}   from './app.component';
import {routing, appRoutingProviders} from "./app.routes";
import {DataService} from "../../services/data.service";
import {DigestService} from "../../services/digest.service";
import {ErrorService} from "../../services/error.service";
import {GetLocaleFactory, SettingsService} from "../../services/settings.service";
import {LoginModule} from "../login/login.module";
import {ArticleService} from "../articles/article.service";
import {CartService} from "../cart/cart.service";
import {SharedAppSettings} from "../../shared-settings/shared-settings";
import {CartIndicatorModule} from "../cart-indicator/cart-indicator.module";
import {PreAuthService} from "../pre-auth/pre-auth.service";
import {SearchSuggestModule} from "../search-suggest/search-suggest.module";
import {HelperComponentsModule} from "../helper-components/helper-components.module";
import {ArticleModule} from "../articles/article.module";
import {CartChangeModalModule} from "../cart-modal/cart-change-modal.module";
import {BreadcrumbsModule} from "../breadcrumbs/breadcrumbs.module";
import {PreloaderModule} from "../preloader/preloader.module";
import {WishListService} from "../../services/wish-list-service";
import {CompareProductsService} from "../../services/compare-products.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MainInterceptor} from "../../interceptors/main.interceptor";
import {IntegrationService} from "../../interceptors/integration.service";
import localeCs from '@angular/common/locales/cs';
import localeSk from '@angular/common/locales/sk';
import localeEn from '@angular/common/locales/en';
import {registerLocaleData} from "@angular/common";
import {CookieWarningModule} from "../cookie-warning/cookie-warning.module";
import {GlobalErrorHandler} from "../../services/global-error-handler.service";
import {CredentialStorage} from "../../services/credential-storage.service";
import {NotifyStripeModule} from "../notify-stripe/notify-stripe.module";
import {CartTokenService} from "../cart/cart-token.service";
import {NewsLetterRegistrationModule} from "../newsletters/newsletter-registration.module";
import {IndexHeaderContactComponent} from "../index/index-header-contact.component";
import {UnpaidInvoicesModule} from "../unpaid-invoices/unpaid-invoices.module";
import {ContactStripeModule} from "../contact-stripe/contact-stripe.module";
import {SmartsuppService} from "../../services/smartsupp.service";
import {PopupB2bModule} from "../popup-b2b/popup-b2b.module";
import {CookiesConsentModule} from "../cookies-consent/cookies-consent.module";
import {GeneralDialogModule} from "../general-dialog/general-dialog.module";
import {CookiesConsentService} from "../cookies-consent/cookies-consent.service";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";
import {AlertModalModule} from "../alert-modal/alert-modal.module";
import { StickyClassModule } from 'app/directives/sticky-class/sticky-class.module';


registerLocaleData(localeCs, 'cs-CZ');
registerLocaleData(localeSk, 'sk-SK');
registerLocaleData(localeEn, 'en-US');

export function LangServiceFactory(seSvc: SettingsService) {
    return () => seSvc.getAllTranslations()
}
export function CookiesInitFactory(ccSvc: CookiesConsentService) {
    return () => ccSvc.setStorageHooks();
}

enableProdMode();

@NgModule({
    imports: [
        BreadcrumbsModule,
        BrowserModule,
        ArticleModule,
        CartIndicatorModule,
        CartChangeModalModule,
        HelperComponentsModule,
        HttpClientModule,
        LoginModule,
        PreloaderModule,
        routing,
        SearchSuggestModule,
        CookieWarningModule,
        NotifyStripeModule,
        UnpaidInvoicesModule,
        ContactStripeModule,
        NewsLetterRegistrationModule,
        PopupB2bModule,
        CookiesConsentModule,
        GeneralDialogModule,
        ImageCacheBustModule,
        AlertModalModule,
        StickyClassModule
    ],
    declarations: [
        AppComponent,
        IndexHeaderContactComponent
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        CredentialStorage,
        IntegrationService,
        ArticleService,
        CartService,
        CartTokenService,
        Title,
        appRoutingProviders,
        DataService,
        DigestService,
        ErrorService,
        CompareProductsService,
        SmartsuppService,
        /**
         * @description
         * If you need your pipes to respond to culture changes
         * you must configure LOCALE_ID provider via settings {@link SettingsService} GetLocaleFactory function
         */
        {
            provide: LOCALE_ID,
            deps: [SettingsService],
            useFactory: GetLocaleFactory
        },
        {
            provide: APP_INITIALIZER,
            useFactory: LangServiceFactory,
            deps: [SettingsService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MainInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: CookiesInitFactory,
            deps: [CookiesConsentService],
            multi: true
        },
        PreAuthService,
        SettingsService,
        SharedAppSettings,
        CookiesConsentService,
        WishListService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
