import {IProduct} from "../product/common";
import {AddressSelector, CompanySelector, InvoiceAddressSelector, UserSelector} from "../address/common";
import {ICompanyUser} from "../account/common";

export interface CartItemSelectorBrief {
    id?: number;
    productId?: number;
    couponId?: number;
    count?: number;
    userId?: number;
    cartToken?: string;
    inserted?: Date;
    fromCart?: boolean;
    disableMessage?: boolean;
}

export interface CartItemSelectorFull extends CartItemSelectorBrief {
    productDetail: IProduct;
    couponDetail: ICoupon;
    GiftProductDetail?: IGiftProduct;

    priceAfterCouponApplied: number;
    priceAfterCouponAppliedWithOutVAT: number;
    wasCouponApplied: boolean;
    priceSum: number;
    priceSumWithOutVAT: number;
    totalCatalogDiscount: number;
}

export interface ICartItemGiftSelector extends CartItemSelectorBrief {

    GiftProductDetail?: IGiftProduct;
}


export interface ICartStatus {
    cartToken: string;
    NotLoggedProductsCnt: number;
    PrevLoggedProductsCnt: number;
}

export interface IOrderToCart {
    orderId: number;
}

export interface IGift {
    id: number;
    name?: string;
    description?: string;
    imagePath?: string;
    thankYouText?: string;
    priceLevel?: number;
    isDisabled?: boolean;

    products?: IGiftProduct[];
}

export interface IGiftProduct {
    id: number;
    giftId: number;
    isFree: boolean;
    discountPercent: number;
    price: number;
    discountType: DiscountType;
    productId: number;
    productName: string;
    productCode: string;
    productImagePath: string;
    productPrice: number;
    productSeoUrl: string;
}

export enum DiscountType {
    isFree = "IsFree",
    discount = "Discount",
    price = "Price"
}

export interface ICoupon {
    currencyId: number;
    id: number;
    name: string;
    code: string;
    validTo?: Date;
    categoryId: number;
    value: number;
    percent: number;
    validFrom?: Date;
    disabled: boolean;
    deleted: boolean;
    maxCountOfAppliances: number;
    appliancesCount: number;
    productId: number;
    productName: string;
    categoryName: string;
    categoryIdPath: string;
    minValue: number;
    isCombinational: boolean;
    restingValue: number;// used only with value-based coupons in cart during the process of calculating cart cart and applying coupons
    couponImpactOnOrderPrice: number;// this is the value that was subtracted from the original order price because of this coupon
    couponImpactOnOrderPriceWithoutVAT: number;
    combinationsCodes: string[];
}

export interface Delivery {
    Id: number;
    Name: string;
    Description?: string;
    Price?: number;
    PriceWithOutVAT?: number;
    ExtraData?: string;
    Logo?: string;
    ShipmentsParse?: IShipmentExtraData;
    CurrencyId?: number;
    B2B: boolean;
    IndividualShipping: boolean;
    IsPersonally: boolean;

    FreeFromPrice?: number;
}

export interface IShipmentExtraData {
    isMailPacket: boolean;  //balik na pobočku
    isMailHand: boolean;  //balik do ruky
    isPpl: boolean; //ppl
    isCp: boolean; // česká pošta
    isStoredRoom: boolean;  //zasilkovna
    isMailBalikovna: boolean// balikovna
}

export interface IDeliveryFreeFrom {
    Id: number;
    FreeFromPrice?: number;
    ShowFreePercent?: number;
}

export class BranchServices {
    name1: string;
    name2: string;
    city: string;
    street: string;
    zipcode: string;
    id: string;
    nameStreet: string;
    ExtraData: string;
    deliveryId?: number;

}

export class ParcelShopsRequest {
    query: string;
    cultureCode?: string;
    type?: string
}

export interface PaymentSelector {
    id: number;
    name: string;
    description?: string;
    priceWithVat?: number;
    priceWithOutVat?: number;
    logo?: string;
    webPayType?:string;
}

export interface IDeliveryPaymentBrief {
    DeliveryId?: number;
    PaymentId?: number;
    IsDefault?: boolean;
}

export interface IWebPaySelector {
    gatewayUrl?: string;
}


export interface IBarionErrorSelector {
    Message: string;
    ErrorCode: string;
}

export interface DeliveryPayment {
    Delivery?: Delivery;
    Payment?: PaymentSelector;

    IndividualDelivery?: boolean;
    DangerousGoods?: boolean;
}


export interface CartAdditionalInfo {
    note?: string;
    logToNewsletter?: boolean;
    maxStep: number;
    withCompany?: boolean;
    withDeliveryAddress?: boolean;
}


export interface ICartActionResult {

    success: boolean;
    errorCode: string;
    extra: object;
}


export interface CartSelector {
    cartActionResult: ICartActionResult;
    cartContent: CartItemSelectorFull[];
    notToOrderCartContent: CartItemSelectorFull[];
    delivery: Delivery;
    payment: PaymentSelector;
    priceTotalWithVat: number;
    priceTotalWithOutVat: number;
    priceWithoutPaymentAndDeliveryWithVat: number;
    priceWithoutPaymentAndDeliveryWithOutVat: number;
    itemsCount: number;
    deliveryFreeFrom: number;
    errorMessage_SenKey: string;
    cartToken: string;
    fromCart: boolean;
    invoiceAddress: InvoiceAddressSelector;
    deliveryAddress: AddressSelector;
    company: CompanySelector;
    user: UserSelector;

    invalidProducts?: InvalidProduct[]

    priceForQuantityDiscountWithoutVAT?: number;
    priceForQuantityDiscountWithVAT?: number;

    //former additional:
    logToNewsletter: boolean,
    maxStep: number,
    withCompany: boolean,
    note: string,
    otherAddress: string,
    withDeliveryAddress: boolean,
    withDifferentAddress: boolean,
    branchInfo?: BranchServices;
    register: boolean;
    customerOrderNumber: string;
    countModified?: boolean;
}

export interface InvalidProduct{
    Product?: IProduct;
    ErrorType: ErrorType;
    ProductCode: string;
    Count?: number;
    MinQtyCount?: number;
}

export enum ErrorType {
    Unknown,
    Count,
    Product
}

export interface OrderSelector {
    header: OrderHeaderSelector;
    items: OrderItemSelector[];
}
export interface OrderHeaderSelectorSimple {
    id: number;
    orderNumber: string;
    guid: string;
    orderDate: any;
    priceSumWithVat: number;
    priceSumWithoutVat: number;
}
export interface OrderHeaderSelectorBrief extends OrderHeaderSelectorSimple {
    note: string;
    email: string;
    paymentType: string;
    shippingType: string;
    packageTrackingUrl: string;
    packageTrackingCode: string;
}

export interface OrderHeaderSelector extends OrderHeaderSelectorBrief {
    userId: number;
    round: number;
    finalPrice: number;
    finalPriceWithoutVAT: number;
    priceSumWithoutVat: number;
    ic: string;
    idOrder?: string;
    dic: string;
    orderState: OrderStateSelector;
    shippingTypePriceWithVat: number;
    shippingTypePriceWithoutVat: number;
    paymentTypePriceWithVat: number;
    paymentTypePriceWithoutVat: number;
    invoiceFirstName: string;
    invoiceLastName: string;
    invoiceCompany: string;
    invoiceStreet: string;
    invoiceCity: string;
    invoiceZipCode: string;
    invoiceCountry: string;
    deliveryFirstName: string;
    deliveryLastName: string;
    deliveryCompany: string;
    deliveryStreet: string;
    deliveryCity: string;
    deliveryZipCode: string;
    deliveryCountry: string;
    phone: string;
    OrderNumber: string;
    orderStateUpdateDate: Date;
    otherAddress: string;
    suborders: OrderHeaderSelectorSimple [];
    deliveryNote: string;
    creatorName?: string;
    mainOrderNumber?: string;
    mainOrderID?: number;
    customerOrderNumber: number;
}

export interface OrderStateSelector {
    orderState: OrderState,
    color: string
}

export enum OrderState {
    PENDING = 'PENDING',
    RECEIVED = 'RECEIVED',
    READY = 'READY',
    COMPLETE = 'COMPLETE',
    PENDINGPAYMENT = 'PENDINGPAYMENT',
    CANCELED = 'CANCELED',
    SHIPPING = 'SHIPPING',
    CARRIERTAKEN = 'CARRIERTAKEN',
}

export interface OrderStatusSelector {
    key: number;
    value: string;
}

export interface OrderItemSelector {
    name: string;
    code: string;
    codeDisplay: string;
    variant: string;
    typeItem: string;
    count: number;
    countSpecified?: number;
    countTransfered?: number;
    countReserved?: number;
    countDelivered?: number;
    countRemains?: number;
    priceWithoutVat: number;
    priceWithVat: number;
    priceSumWithVat: number;
    priceSumWithoutVat: number;
    seoUrl: string;
    imagePath: string;
    productId: number;
    masterProductId: number;
    unit: string;
    parts: OrderItemPart[];
    discountPercent: number;
    
}
export interface OrderItemPart {
    partNumber: number;
    code: string;
    orderId: number;
    orderNumber: string;
    count: number;
    unit: string;
}
export interface AddDeliveryPaymentRequest {
    deliveryId: number;
    paymentId: number;
}

export enum CouponExtraKey {
    ProductName = "ProductName",
    ProductId = "ProductId",
    CategoryId = "CategoryId",
    CategoryName = "CategoryName",
    PriceLimit = "PriceLimit"
}

export enum CouponApplicability {
    CouponNotFound = 'CouponNotFound',
    CountOfApplicationWasReached = 'CountOfApplicationWasReached',
    CouponIsNotValidYet = 'CouponIsNotValidYet',
    CouponIsNotValidAlready = 'CouponIsNotValidAlready',
    CouponIsApplicableOnlyOnProduct = 'CouponIsApplicableOnlyOnProduct',
    CouponIsApplicableOnlyOnCategory = 'CouponIsApplicableOnlyOnCategory',
    CouponIsAppliedAlready = 'CouponIsAppliedAlready',
    CouponCannotBeCombined = 'CouponCannotBeCombined',
    OrderPriceLimitWasNotReached = 'OrderPriceLimitWasNotReached',
    OK = 'OK'
}

export interface IGATransaction {
    id: string;
    affiliation?: string;
    revenue?: number;
    shipping?: number;
    tax?: number;
}

export interface IGAItem {
    id: string;
    name: string;
    sku?: string;
    category?: string;
    price?: number;
    quantity?: number;
}

export interface IPlaceOrderResult {
    success: IPlaceOrderSuccessResult;
    fail: IPlaceOrderFailResult;
}

export interface IPlaceOrderSuccessResult {
    orderId: number;
    hash: string;
}

export interface IPlaceOrderFailResult {
    online?: boolean;
    updatedItems?: any[];
    dbSave?: boolean;
}

export interface ICartProductGift {
    product: IProduct;
    gift: IProduct;
    countLeft: number;
}

export interface CartCheckSelector {
    couponRemoved: boolean;
    giftRemoved: boolean;
    productsRemoved: boolean;
    productsOverStock: number[];
    promotionsOk: boolean;
    cartCompareResult: CartCompareResultSelector;
}

export interface CartCompareResultSelector {
    isCountChange: boolean;
    isPriceChange: boolean;
    isAvailabilityChange: boolean;
    isDeliveryDateChange: boolean;
    products: Array<CartCompareProductResultSelector>;
    actualCart: CartSelector;
}

export interface CartCompareProductResultSelector {
    productId: number;
}

export interface CartCheckRequest {
    withPromotions: boolean;
    withCompare: boolean;
    clientCart: CartSelector;
}

export interface CartDialogConfig {
    cartItem: CartItemSelectorFull
}

export interface CartDialogMultiConfig {
    validItems: CartDialogConfig[];
    invalidProducts?: InvalidProduct[]
}

export interface UserPUTDialogConfig {
    user?: ICompanyUser;
    userEdit: 'edit' | 'create' | null;
}

export interface UserRemoveDialogConfig {
    user: ICompanyUser;
}