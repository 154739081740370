/**
 * @description
 * Serves as a container for front end application settings
 * Place only things that are really needed
 */
import {Injectable} from '@angular/core';
import {
    Culture,
    StringIndexedObject,
    SettingItem,
    Settings,
    IDomainInfo, GaSelector, SeznamSelector, HeurekaSelector, ParametricFilterRequest,
} from "../interfaces/general";
import {SharedAppSettings} from "../shared-settings/shared-settings";
import {loadFromLocalStorage, loadFromSession, saveToSession} from "../helpers/cookie.helper";
import {IntegrationService} from "../interceptors/integration.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map, tap} from "rxjs/operators";
import {ReplaySubject} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

let settings: Settings =
    {
        preAuth: false,
        preAuthToLocal: true,
        preAuthLocalExpiration: 12,
        allowPermanentLogin: false,
        cartAdjustAction: "ask",
        pageSizes: [{value: 21}, {value: 42}, {value: 63}],
        countries: [
            {
                id: 326,
                name: 'Česká republika'
            }
        ],
        cultures: [
            {
                code: "cs-CZ",
                cultureId: 34,
                name: "Česky",
                currencyId: 3,
                currencyCode: "CZK",
                currencySymbol: "Kč",
                translationKey: "app-czech",
                htmlLangAttribute: "cs",
                cultureBoundDomain: ""
            },
            {
                code: "sk-SK",
                name: "Slovensky",
                currencyId: 2,
                currencyCode: "EUR",
                currencySymbol: "€",
                cultureId: 102,
                translationKey: "app-slovak",
                htmlLangAttribute: "sk",
                cultureBoundDomain: "",
            },
            {
                code: "en-US",
                name: "English",
                currencyId: 2,
                currencyCode: "EUR",
                currencySymbol: "€",
                cultureId: 50,
                translationKey: "app-english",
                htmlLangAttribute: "en",
                cultureBoundDomain: ""
            }
        ],
        validationPatterns: {
            email: /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*(\+[_a-zA-Z0-9-]+)?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,8})$/,
            naturalNumber: /^[1-9][0-9]*$/,
            decimalNumber:   /^(\d*[.,])?\d+$//*/^(\d{1,3}(\s\d{3})*(\.\d+)?|\d+)$/*/,
            phone: {
                34: /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                102: /^(\+421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
                50: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/
            },
            zipCodeCz: /^\d{3}\s?\d{2}$/
        },
        imageServerPathPrefix: "https://img.askocz002.scdev.cz",
        localDomain: "http://localhost/asko",
        currentServerUrl: "",
        decimalSettings: null,
        dateSettings: {
            34: {
                DatePattern: 'D. M. YYYY',
                TimePattern: 'HH:mm',
                DateTimePattern: 'D. M. YYYY HH:mm'
            },
            50: {
                DatePattern: 'D. M. YYYY',
                TimePattern: 'HH:mm',
                DateTimePattern: 'D. M. YYYY HH:mm'
            },
            102: {
                DatePattern: 'D. M. YYYY',
                TimePattern: 'HH:mm',
                DateTimePattern: 'D. M. YYYY HH:mm'
            }
        },
        assetPathPrefix: 'app/common/classic',
        termsAndConditions: {}

    };

export function GetLocaleFactory(seSvc: SettingsService) {
    return seSvc.getLocale()
}

@Injectable()
export class SettingsService {

    startUpSettingsAreSet: ReplaySubject<any> = new ReplaySubject<any>(1);

    private _cacheBustKey: string;
    get cacheBustKey(): string {
        return this._cacheBustKey;
    }

    set cacheBustKey(value: string) {
        this._cacheBustKey = value;
    }

    private _sen: StringIndexedObject;
    get sen(): StringIndexedObject {
        return this._sen;
    }

    set sen(value: StringIndexedObject) {
        this._sen = value;
        Sentences.sen = value;
    }

    private _culture: Culture;
    get culture(): Culture {
        return this._culture;
    }

    set culture(value: Culture) {
        this._culture = value;
    }
    private _gaSettings: GaSelector;
    get gaSettings(): GaSelector {
        return this._gaSettings;
    }

    set gaSettings(value: GaSelector) {
        this._gaSettings = value;
    }

    private _seznamSettings: SeznamSelector;
    get seznamSettings(): SeznamSelector {
        return this._seznamSettings;
    }

    set seznamSettings(value: SeznamSelector) {
        this._seznamSettings = value;
    }

    private _heurekaSettings: HeurekaSelector;
    get heurekaSettings(): HeurekaSelector {
        return this._heurekaSettings;
    }

    set heurekaSettings(value: HeurekaSelector) {
        this._heurekaSettings = value;
    }
    settings: Settings;
    sharedAppDefaults: StringIndexedObject;
    currencyId: number;
    defCulture: Culture;
    defCurrency: number;
    comAllowed: boolean = false;
    domainInfo: IDomainInfo;

    selectedFlags: string[];
    selectedProducers: number[];
    selectedPriceRange: number[];
    selectedParametric: ParametricFilterRequest;

    showPopUpB2B: boolean = false;
    get visitedPopUpB2B(): boolean {
        return !!sessionStorage.getItem('popupB2B');
    }
    set visitedPopUpB2B(val: boolean) {
        sessionStorage.setItem('popupB2B', ""+val);
    }

    private _modalOpen: boolean = false;
    get modalOpen(): boolean {
        return this._modalOpen;
    }

    set modalOpen(value: boolean) {
        this._modalOpen = value;
    }

    private _loginOpen: boolean = false;
    get loginOpen(): boolean {
        return this._loginOpen;
    }

    set loginOpen(value: boolean) {
        this._loginOpen = value;
    }

    constructor(saSvc: SharedAppSettings, private http: HttpClient, private iSvc: IntegrationService, private sanitizer: DomSanitizer) {

        this.settings = settings;
        this.sharedAppDefaults = saSvc.Defaults;

        // Culture and Currency are retrieved from the shared application settings
        // and valid either until user logs in or changes them manually
        this.defCulture = this.settings.cultures.filter((c) => c.cultureId == this.sharedAppDefaults['CultureId'])[0];
        this.defCurrency = this.sharedAppDefaults['CurrencyId'];

        let cult = <Culture>loadFromSession('cult');
        if (cult) {

            this.culture = cult;
        } else {
            this.culture = this.defCulture;
            saveToSession('cult', this.culture)
        }
        let curr = loadFromSession('curr');
        if (curr) {
            this.currencyId = curr;
        } else {
            this.currencyId = this.defCurrency;
            saveToSession('curr', this.currencyId)
        }

        SettingsService.setHtmlLangAttribute(this.culture.htmlLangAttribute);
    }

    setCulture(cultureId: number, currencyId?: number) {
        this.setCultureAndReload(false, cultureId, currencyId);
    }

    setCultureAndReload(reload: boolean, cultureId: number, currencyId?: number) {

        this.culture = this.settings.cultures.filter(c => c.cultureId == cultureId)[0];
        saveToSession('cult', this.culture);

        if (currencyId) {
            this.currencyId = currencyId;
            saveToSession('curr', this.currencyId);
        }

        SettingsService.setHtmlLangAttribute(this.culture.htmlLangAttribute);

        if (reload) location.reload();
    }

    private setStartUpSettings(settingMap: Map<string, any>): void {

        this.settings.imageServerPathPrefix = settingMap.get('ImageServer');
        this.settings.currentServerUrl = settingMap.get('ServerUrl');
        this.settings.preAuth = settingMap.get('PreAuthEnabled') === "true";
        this.settings.decimalSettings = JSON.parse(settingMap.get('DecimalSettings'));
        this.settings.smartsupp = JSON.parse(settingMap.get('SmartsuppKey'));
        this.settings.assetPathPrefix = settingMap.get('AssetPathPrefix');
        this.settings.termsAndConditions = JSON.parse(settingMap.get('TermsAndConditionsUrl'));
        this.gaSettings = settingMap.get('GaSettings');
        this.seznamSettings = settingMap.get('SeznamSettings');
        this.heurekaSettings = settingMap.get('HeurekaSettings');
        this.cacheBustKey = settingMap.get('ImageCacheBust');

        let pa = this.settings.preAuthToLocal ? loadFromLocalStorage('pa') : loadFromSession('pa');
        this.comAllowed = pa || !this.settings.preAuth;
        this.setCurrentServerUrl();

        this.startUpSettingsAreSet.next();
    }

    private setUpApplication(input: any): any {
        const m = SettingsService.makeSettingsMap(input['StartUpSettings']);
        this.setStartUpSettings(m);
        this.domainInfo = input['DomainInfo'];
        let culture = (this.domainInfo && this.domainInfo.cultureId) || this.defCulture.cultureId;
        let currency = (this.domainInfo && this.domainInfo.currencyId) || this.defCurrency;
        this.setCulture(culture, currency);

        this.iSvc.httpRegional.cultureId = this.culture.cultureId;
        this.iSvc.httpRegional.currencyId = this.currencyId;

        return this.makeSentenceObject(input['Translations']);
    }

    getAllTranslations(): any {

        let options = {headers: new HttpHeaders()};
        //options.headers.set('F-CC', `cu:${this.culture.cultureId},cy:${this.currencyId}`);

        this.iSvc.httpRegional = {
            comAllowed: this.comAllowed,
            cultureId: this.culture.cultureId,
            currencyId: this.currencyId
        };

        let result = this.http.get(`api/lang`, options)
            .pipe(
                map((res: any) => this.setUpApplication(res)),
                tap((res: any) => {
                    this.sen = res;
                    this.iSvc.httpRegional.comAllowed = this.comAllowed;
                })
            );
        return result.toPromise()
    }

    getLocale(): string {
        return this.culture.code;
    }

    private makeSentenceObject(input: Object[]): Object {
        let tmp: StringIndexedObject = {};
        if (input) {
            input.forEach((e: any) => tmp[e['Key']] = e['Value']);
        }
        return tmp;
    }

    private static makeSettingsMap(items: SettingItem[]): Map<string, any> {
        let m: Map<string, any> = new Map();
        for (let item of items) {
            m.set(item.Key, item.Value);
        }
        return m;
    }

    private static setHtmlLangAttribute(lang: string): void {
        document.querySelector('html').setAttribute('lang', lang);
    }

    private setCurrentServerUrl(): void {
        sessionStorage.setItem('currentServerUrl', this.settings.currentServerUrl)
    }

    public svg(id: string): SafeHtml {
        const content = `<svg> <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="app/themes/classic/assets/img/sprite.svg?v=${this.cacheBustKey}#${id}"/> </svg>`;
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }

    copyToClipboard(value: string): void {
        // Vytvoření dočasného prvku pro zkopírování do schránky
        const tempEl: HTMLTextAreaElement = document.createElement('textarea');
        tempEl.value = value;
        document.body.appendChild(tempEl);
        tempEl.select();
        document.execCommand('copy');
        document.body.removeChild(tempEl);
    }

}
export class Sentences {
    public static sen: StringIndexedObject;
}