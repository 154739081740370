import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Translatable} from "../../interfaces/general";
import {IProductAmountSettings} from "./interfaces/IProductAmountSettings";
import {IProductAmount, IProductAmountConfirm} from "./interfaces/IProductAmountConfirm";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {Numeric} from "../../helpers/numeric.helper";
import {MathUtils} from "../../helpers/MathUtils";

@Component({
    selector: 'cmp-product-amount',
    templateUrl: '../../tpl/product-amount.html',
    styleUrls: ['../../../assets/styles/2-components/amount.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProductAmountComponent extends Translatable implements OnInit, OnChanges {

    @Input() classModificator: string;
    @Input() isDisabled: boolean = false;
    @Input() isLarge: boolean = false;

    @Input() data: IProductAmountSettings = null;
    @Output() confirmed: EventEmitter<IProductAmountConfirm> = new EventEmitter<IProductAmountConfirm>();

    @Input() amount: number = null;
    @Output() amountChange: EventEmitter<number> = new EventEmitter<number>();

    @Output() amountHandler: EventEmitter<IProductAmount> = new EventEmitter<IProductAmount>();

    public form: FormGroup = null;

    constructor(
        seSvc: SettingsService,
        dataSvc: DataService,
        private fb: FormBuilder
    ) {
        super(dataSvc, seSvc);

        this.form = this.fb.group({
            quantity: [1, [Validators.required, Validators.pattern(this.seSvc.settings.validationPatterns.decimalNumber), Validators.maxLength(6)]],
            productID: null
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data'] && changes['data'].currentValue) {
            let formatted = new Intl.NumberFormat('cs-CZ', {maximumFractionDigits : 4}).format(this.data.MinQuantity);
            this.form.patchValue({
                quantity: formatted,
                productID: this.data === null ? null : this.data.ProductId
            });
        }
        else if (changes['data'] && changes['data'].currentValue === null) {
            this.form.patchValue({
                quantity: 1,
                productID: this.data === null ? null : this.data.ProductId
            });

        }
        if (changes['amount'] && changes['amount'].firstChange && Numeric.isNumber(changes['amount'].currentValue)) {
            let formatted = new Intl.NumberFormat('cs-CZ', {maximumFractionDigits : 4}).format(this.amount);
            this.form.patchValue({
                quantity: formatted,
                productID: this.data === null ? null : this.data.ProductId
            });

        }
    }

    public SubtractAmount(elem: HTMLInputElement): void {
        const step: number = this.data.Factor || 1;
        const val: number = parseFloat((<HTMLInputElement>elem).value.replace(',', '.').replace(/\s/g, ""));
        let newVal = null;
        if (Number.isNaN(val)) {
            newVal = this.data.MinQuantity;
        }
        else {
            newVal = val - step;
            if (!MathUtils.IsMultiple(newVal, step)) {
                newVal = val - val % step;
            }
            newVal = parseFloat(newVal.toFixed(5));
        }

        if (newVal < this.data.MinQuantity)
            newVal = this.data.MinQuantity;

        if (this.Amount !== newVal) {
            let formatted = new Intl.NumberFormat('cs-CZ',{maximumFractionDigits : 4}).format(newVal);
            this.form.patchValue({
                quantity: formatted,
                productID: this.data.ProductId
            });

            if (this.IsValid)
                this.amountChange.emit(this.Amount);
        }
    }

    public AddAmount(elem: HTMLInputElement): void {
        const step: number = this.data.Factor || 1;
        let val: number = parseFloat((<HTMLInputElement>elem).value.replace(',', '.').replace(/\s/g, ""));
        let newVal = null;
        if (Number.isNaN(val)) {
            newVal = this.data.MinQuantity;
        }
        else {
            newVal = val + step;
            if (!MathUtils.IsMultiple(newVal, step)) {
                newVal = newVal - newVal % step;
            }
            newVal = parseFloat(newVal.toFixed(5));
        }
        let formatted = new Intl.NumberFormat('cs-CZ',{maximumFractionDigits : 4}).format(newVal);
        this.form.patchValue({
            quantity: formatted,
            productID: this.data.ProductId
        });

        if (this.IsValid)
            this.amountChange.emit(this.Amount);
    }

    public GetMinAmount(): number {
        if (!this.data || !this.data.MinQuantity) return 0;
        return this.data.MinQuantity;
    }

    public GetMaxAmount(): number {
        if (!this.data || !this.data.MaxQuantity) return Number.MAX_VALUE;
        return this.data.MaxQuantity;
    }

    public IsMultiple(): boolean {
        if (!this.data || !this.data.Factor)
            return true;

        const res = MathUtils.IsMultiple(this.Amount, this.data.Factor);
        return res;
    }

    public KeyUp(evt: KeyboardEvent): void {
        if (evt.key === 'Enter' || evt.key == 'NumpadEnter') {
            if (this.form.valid) {
                this.confirmed.emit(this.GetAmountConfirm());
            }
        }
    }

    public GetAmountConfirm(): IProductAmountConfirm {
        return this.form.valid ? {
            Amount: this.Amount
        } : null;
    }

    public get Amount(): number {
        try {
            const value = this.form.get('quantity').value;
            if (value) {
                const parsedValue = parseFloat(value.toString().replace(',', '.').replace(/\s/g, ""));
                if (!Number.isNaN(parsedValue)) {
                    this.amountHandler.emit( {
                        Amount: parsedValue,
                        ProductID: this.data.ProductId
                    });
                    return parsedValue;
                }
            }
        }
        catch {
            return null;
        }
        return null;
    }

    public InputChange(): void {
        if (this.IsValid)
            this.amountChange.emit(this.Amount);
    }

    public get IsValid(): boolean {
        return /*this.form.valid &&*/ this.GetMinAmount() <= this.Amount && this.IsMultiple();
    }
    public get getModificatorClass():string{
        return `amount--${this.classModificator}`;
    }
}
