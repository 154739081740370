import {Directive, ElementRef, Input} from "@angular/core";

@Directive({selector: '[sticky]'})
export class StickyClassDirective {

    @Input() stickyClass: string;
    @Input() ratio: number = 1;

    private observer: IntersectionObserver;

    constructor(private elRef: ElementRef) {
        this.observer = new IntersectionObserver(([el]) => {
            this.elRef.nativeElement.classList.toggle(this.stickyClass, (el.intersectionRatio < this.ratio));
        }, { threshold: [this.ratio] });

        this.observer.observe(this.elRef.nativeElement);
    }
}