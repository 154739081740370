import {Component, ViewEncapsulation} from "@angular/core";
import {IProductList, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {CartItemSelectorFull, InvalidProduct} from "../cart/common";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {GeneralDialogRef} from "../general-dialog/common";
import {CartDialogMultiConfig} from "../cart/common";
import {I18nPluralPipe} from "@angular/common";


@Component({
    selector: 'cmp-cart-change-multi-modal',
    templateUrl: '../../tpl/cart-change-modal-list.html',
    styleUrls: ['../../../assets/styles/2-components/cart-change-modal.scss'],
    providers: [I18nPluralPipe],
    encapsulation: ViewEncapsulation.None
})

export class CartChangeModalListComponent extends Translatable {

    cartItems: CartItemSelectorFull[];
    failItems: InvalidProduct[];
    productImagePathPrefix: string;
    timeoutId: any;
    timeOutDelay: number = 0;
    decimalPattern: string = '1.0-0';
    currencySymbol: string;
    productPlural: {} = {};
    textPlural: {} = {};
    text:string;

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                private config: GeneralDialogConfig<CartDialogMultiConfig>,
                private dialogRef: GeneralDialogRef, private i18: I18nPluralPipe) {
        super(dataSvc, seSvc);

        this.productPlural = {
            '=0': `# ${this.sen['0--product']}`,
            '=1': `# ${this.sen['1-product']}`,
            '=2': `# ${this.sen['2-3-4--product']}`,
            '=3': `# ${this.sen['2-3-4--product']}`,
            '=4': `# ${this.sen['2-3-4--product']}`,
            'other': `# ${this.sen['5-and-more--product']}`
        };
        this.textPlural = {
            '=0': `${this.sen['0-cart-modal-insert-list-text']}`,
            '=1': `${this.sen['1-cart-modal-insert-list-text']}`,
            '=2': `${this.sen['2-3-4--cart-modal-insert-list-text']}`,
            '=3': `${this.sen['2-3-4--cart-modal-insert-list-text']}`,
            '=4': `${this.sen['2-3-4--cart-modal-insert-list-text']}`,
            'other': `${this.sen['5-and-more--cart-modal-insert-list-text']}`
        };

        if (this.config && this.config.data) {
            this.cartItems = this.config.data.validItems !== null ? this.config.data.validItems.map(x => x.cartItem) : null;
            this.failItems = this.config.data.invalidProducts;

            // Changed in ticket 248811.
            // const totalCount = this.cartItems ? this.cartItems.reduce((acc, item) => acc + item.count, 0) : 0;
            const totalCount = this.cartItems ? this.cartItems.length : 0;
            let plr = this.i18.transform(totalCount, this.productPlural);
            let txt = this.i18.transform(totalCount, this.textPlural);
            this.text = txt.replace("[CNT]", plr);
        }

        this.productImagePathPrefix = seSvc.settings.imageServerPathPrefix + '/fotocache/basket/images/';
        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.ProductBox}-${this.seSvc.settings.decimalSettings.Basket}`;

        if (this.timeOutDelay > 0) {
            if (this.timeoutId) clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.closeCartAddDialog();
            }, this.timeOutDelay);
        }
    }

    ngOnInit() {

    }

    closeCartAddDialog(): void {
        this.dialogRef.close(this.config.data.invalidProducts);
        this.cartItems = null;
    }

    productClick(_item: IProductList): void {
        this.closeCartAddDialog();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    getProductRouterLink(item: CartItemSelectorFull): string[] {
        return ['/',
            '' + item.productDetail.MasterInfo.entityPrefix,
            '' + item.productDetail.MasterInfo.targetId,
            '' + item.productDetail.MasterInfo.targetSeoTail
        ];
    }

    getMessageByErrorType(value: number, count: number): string{
        switch (value) {
            case 1:
                return this.sen['cart-change-modal-count-error'] + count;
            case 2:
                return this.sen['cart-change-modal-product-error'];
            default:
                return this.sen['cart-change-modal-unknown-error'];
        }
    }
}
