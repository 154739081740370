import {Component, ViewEncapsulation} from "@angular/core";
import {IProductList, Translatable} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";
import {DataService} from "../../services/data.service";
import {CartDialogConfig, CartItemSelectorFull} from "../cart/common";
import {GeneralDialogConfig} from "../general-dialog/general-dialog-config";
import {GeneralDialogRef} from "../general-dialog/common";
import {IProductAmountConfirm} from "../product-amount/interfaces/IProductAmountConfirm";



declare let $: any;

@Component({
    selector: 'cmp-cart-change-modal',
    templateUrl: '../../tpl/cart-change-modal.html',
    styleUrls: ['../../../assets/styles/2-components/buy-window.scss'],
    providers: [GeneralDialogConfig, GeneralDialogRef],
    encapsulation: ViewEncapsulation.None
})

export class CartChangeModalComponent extends Translatable {

    cartItem: CartItemSelectorFull;
    productImagePathPrefix: string;
    timeoutId: any;
    timeOutDelay: number = 0;
    decimalPattern: string = '1.0-0';
    currencySymbol: string;
    hideRelated: boolean = false;

    constructor(public dataSvc: DataService, public seSvc: SettingsService,
                // public cartSvc: CartService,
                private config: GeneralDialogConfig<CartDialogConfig>,
                private dialogRef: GeneralDialogRef) {
        super(dataSvc, seSvc);

        if (this.config && this.config.data) {
            this.cartItem = this.config.data.cartItem;
        }

        // this.currencySymbol = this.seSvc.settings.currencies[this.seSvc.currencyId];
        this.decimalPattern = `1.${this.seSvc.settings.decimalSettings.ProductBox}-${this.seSvc.settings.decimalSettings.Basket}`;
        this.productImagePathPrefix = seSvc.settings.imageServerPathPrefix + '/fotocache/basket/images/';
        if (this.timeOutDelay > 0) {
            if (this.timeoutId) clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.closeCartAddDialog();
            }, this.timeOutDelay);
        }
    }

    closeCartAddDialog(): void {
        this.dialogRef.close();
        this.cartItem = null;
    }

    productClick(_item: IProductList): void {
        this.closeCartAddDialog();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    onCountChanged(cartItem: CartItemSelectorFull, value: number){
        this.changeCount(cartItem, value.toString());
    }
    onConfirm(cartItem: CartItemSelectorFull, value: IProductAmountConfirm){
        this.changeCount(cartItem, value.Amount.toString());
    }
    //region Amount change

    changeCount(cartItem: CartItemSelectorFull, newCount: string, delta?: number): void {


        if ($.isNumeric(newCount)) {
            let newCountNumber = parseFloat(newCount.toString());
            // toto nemuze fungovat pro desetine cisla
            /*if (!delta && !this.isValidCount(newCountNumber, cartItem.productDetail.MinQtyOrder)) {
                return;
            }*/

            if (delta && delta + newCountNumber >= 0) {
                newCountNumber = Math.max((cartItem.productDetail.MinQtyOrder || 1), newCountNumber + delta);
                newCountNumber = Math.floor(newCountNumber / (cartItem.productDetail.MinQtyOrder || 1)) * (cartItem.productDetail.MinQtyOrder || 1)
            }
            if (newCountNumber >= cartItem.productDetail.MinQtyOrder) {
                // let cart: CartItemSelectorBrief = {
                //     count: newCountNumber,
                //     id: cartItem.id,
                //     productId: cartItem.productId
                // };
                // this.cartSvc.changeCount(cart);
            }
        }
    }
}
