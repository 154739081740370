import {Component, Input, ViewEncapsulation} from "@angular/core";
import {IProduct, PriceTypes, RenderStyle} from "./common";
import {IProductList, Translatable} from "../../interfaces/general";
import {DataService} from "../../services/data.service";
import {SettingsService} from "../../services/settings.service";



@Component({
    selector: 'cmp-product-price-handler',
    templateUrl: '../../tpl/product-price-handler.html',
    styleUrls: ['../../../assets/styles/2-components/product-price-handler.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ProductPriceHandlerComponent extends Translatable {

    // Inputs:
    @Input() product: IProduct | IProductList;

    @Input() renderStyle: RenderStyle = RenderStyle.Box;

    @Input() currencyCode: string;
    @Input() currencyDisplay: string;
    @Input() currencyPattern: string;

    @Input() actionDiscount: number;

    @Input() isVariant: boolean;
    @Input() isB2B: boolean;
    @Input() isUserLoggedIn: boolean;

    // Variables:
    public isAction: boolean = false;
    public isSellOut: boolean = false;

    public renderStyles = RenderStyle;

    public priceTypes = PriceTypes;

    // Condition variables
    public isActionSelloutPriceCondition: boolean = false;
    public isOnlyActionCondition: boolean = false;
    public isOnlySellOutCondition: boolean = false;
    public isBasePriceDifferentCondition: boolean = false;

    public isActionPriceLowerThanUserPrice: boolean = false;
    public isActionPriceHigherOrEvenThanUserPrice: boolean = false;

    public isSelloutPriceLowerThanUserPrice: boolean = false;
    public isSelloutPriceHigherOrEvenThanUserPrice: boolean = false;

    public isPriceLowerThanBasePrice: boolean = false;

    public isActionPriceHigherThanIndividualPrice: boolean = false;

    public isActionPriceHigherThanPriceListPrice: boolean = false;

    // Style variables
    public styleClasses: any = {
        // Base prices
        basePriceStyle: 'base-price',
        basePriceHighlightStyle: 'base-price-highlight',
        basePriceHighlightLineThroughStyle: 'base-price-highlight-line-through',
        // Main prices
        mainPriceStyle: 'main-price',
        mainPriceTextStyle: 'main-price-text',
        mainPriceLineThroughStyle: 'main-price-line-through',
        mainPriceHighlightStyle: 'main-price-highlight',
        mainPriceHighlightActionStyle: 'main-price-highlight-action',
        // Common prices
        commonPriceStyle: 'common-price',
        commonPriceHighlightStyle: 'common-price-highlight',
        commonPriceHighlightActionStyle: 'common-price-highlight-action',
        commonPriceTextStyle: 'common-price-text'
    };
    public priceStyleClasses = {
        basePriceClasses: {},
        mainPriceClasses: {},
        commonPriceClasses: {},
    };

    constructor(public dataSvc: DataService, public seSvc: SettingsService){
        super(dataSvc, seSvc);
    }

    ngOnInit(): void {
        this.isAction = this.product.IsAction !== null ? this.product.IsAction : false;
        this.isSellOut = this.product.IsSellOut !== null ? this.product.IsSellOut : false;

        this.setConditions();
        this.setClassBindings();
    }

    setConditions(): void {
        if (!this.product) return;
        const basePrice = this.product.BasePrice;
        const basePriceWVAT = this.product.BasePriceWVAT;
        const priceWithVAT = this.product.PriceWithVAT;
        const priceWithoutVAT = this.product.PriceWithoutVAT;
        const defaultPrice = this.product.BasePrice === this.product.DefaultPricelistPriceWithoutVAT ? this.product.BasePrice : this.product.DefaultPricelistPriceWithoutVAT;

        this.isActionSelloutPriceCondition = this.isAction && !this.isSellOut && priceWithVAT >= basePriceWVAT;
        this.isOnlyActionCondition = this.isAction && !this.isSellOut;
        this.isOnlySellOutCondition = !this.isAction && this.isSellOut;
        this.isBasePriceDifferentCondition = basePrice > 0 && (this.isAction || (this.isSellOut && this.product.DefaultSalePriceWithoutVAT)) && this.renderStyle !== RenderStyle.Whisper;

        this.isActionPriceLowerThanUserPrice = this.isAction && priceWithoutVAT < basePrice;
        this.isActionPriceHigherOrEvenThanUserPrice = this.isAction && priceWithoutVAT >= basePrice;

        this.isSelloutPriceLowerThanUserPrice = this.isSellOut && priceWithoutVAT < basePrice;

        this.isSelloutPriceHigherOrEvenThanUserPrice = this.isSellOut && priceWithoutVAT >= basePrice && (this.product.IsDifferentPriceListThanDefault || this.product.HasBasePriceList);

        this.isPriceLowerThanBasePrice = !this.isSellOut && !this.isAction && basePrice <= defaultPrice && (this.product.IsDifferentPriceListThanDefault || this.product.HasBasePriceList);

        if (this.product.DefaultSalePriceWithoutVAT){
            this.isActionPriceHigherThanIndividualPrice = !this.product.IsDifferentPriceListThanDefault && this.product.DefaultPricelistPriceWithoutVAT <= this.product.DefaultSalePriceWithoutVAT;
            this.isActionPriceHigherThanPriceListPrice = this.product.BasePrice > this.product.DefaultPricelistPriceWithoutVAT && this.product.DefaultSalePriceWithoutVAT > this.product.DefaultPricelistPriceWithoutVAT;
        }
    }

    getPrice(priceType: PriceTypes): number {
        const isRenderTableDPH = this.renderStyle === this.renderStyles.TableDPH;
        const lowestPriceWithoutVAT = this.product.BasePrice <= this.product.PriceWithoutVAT ? this.product.BasePrice : this.product.PriceWithoutVAT;
        const lowestPrice = this.product.BasePriceWVAT <= this.product.PriceWithVAT ? this.product.BasePriceWVAT : this.product.PriceWithVAT;
        const basePriceAction = this.product.SalePriceWithVAT > 0 ? this.product.SalePriceWithVAT : this.product.PriceWithVAT;
        const basePriceActionWithoutVAT = this.product.SalePriceWithoutVAT > 0 ? this.product.SalePriceWithoutVAT : this.product.PriceWithoutVAT;

        const getPriceBasedOnConditions = (
            priceWithVAT: number,
            priceWithoutVAT: number,
            defaultPrice: number,

        ): number => {
            if (this.isPriceLowerThanBasePrice) return priceWithoutVAT;
            if (this.isSelloutPriceLowerThanUserPrice || this.isActionPriceLowerThanUserPrice) return priceWithVAT;
            if (this.isSelloutPriceHigherOrEvenThanUserPrice || this.isActionPriceHigherOrEvenThanUserPrice) return priceWithoutVAT;
            return defaultPrice;
        };

        const getB2BPrice = (price: number, defaultPrice: number): number => this.isB2B ? price : defaultPrice;

        switch (priceType) {
            case PriceTypes.BasePrice:
                if (isRenderTableDPH) return getPriceBasedOnConditions(
                    this.product.BasePriceWVAT,
                    this.product.DefaultPricelistPriceWithVAT,
                    this.product.BasePriceWVAT
                );
                if (this.isB2B) return getPriceBasedOnConditions(
                    this.product.BasePrice,
                    this.product.DefaultPricelistPriceWithoutVAT,
                    this.product.BasePrice
                );
                return this.product.BasePriceWVAT;
            case PriceTypes.BasePriceAction:
                return isRenderTableDPH ? basePriceAction : getB2BPrice(basePriceActionWithoutVAT, basePriceAction);
            case PriceTypes.MainPrice:
                return getB2BPrice(lowestPriceWithoutVAT, lowestPrice);
            case PriceTypes.MainPriceAction:
                return getB2BPrice(lowestPriceWithoutVAT, lowestPrice);
            case PriceTypes.MainPriceVariants:
                return getB2BPrice(this.product.PriceFromWithoutVAT, this.product.PriceFromWithVAT);
            case PriceTypes.CommonPrice:
                return getB2BPrice(lowestPrice, lowestPriceWithoutVAT);
            case PriceTypes.CommonPriceAction:
                return getB2BPrice(lowestPrice, lowestPriceWithoutVAT);
            case PriceTypes.CommonPriceVariants:
                return getB2BPrice(this.product.PriceFromWithVAT, this.product.PriceFromWithoutVAT);
            default:
                return 0;
        }
    }


    setClassBindings(): void {
        if (this.isActionSelloutPriceCondition || this.isActionPriceHigherThanIndividualPrice){
            this.priceStyleClasses.basePriceClasses = {
                [this.styleClasses.basePriceStyle]: true,
                [this.styleClasses.basePriceHighlightStyle]: true,
                [this.styleClasses.basePriceHighlightLineThroughStyle]: this.isOnlyActionCondition,
            };
            this.priceStyleClasses.mainPriceClasses = {
                [this.styleClasses.mainPriceStyle]: true,
                [this.styleClasses.mainPriceHighlightActionStyle]: this.isOnlyActionCondition && !this.isActionPriceHigherThanIndividualPrice && this.product.IsDifferentPriceListThanDefault,
            };
            this.priceStyleClasses.commonPriceClasses = {
                [this.styleClasses.commonPriceStyle]: true,
                [this.styleClasses.commonPriceHighlightStyle]: false,
                [this.styleClasses.commonPriceHighlightActionStyle]: this.isOnlyActionCondition && this.renderStyle === this.renderStyles.TableDPH
            }
        } else {
            this.priceStyleClasses.basePriceClasses = {
                [this.styleClasses.basePriceStyle]: true,
                [this.styleClasses.basePriceHighlightStyle]: false,
                [this.styleClasses.basePriceHighlightLineThroughStyle]: this.isOnlyActionCondition || this.isOnlySellOutCondition,
            };
            this.priceStyleClasses.mainPriceClasses = {
                [this.styleClasses.mainPriceStyle]: true,
                [this.styleClasses.mainPriceHighlightStyle]: this.isOnlyActionCondition && !this.isActionPriceHigherThanPriceListPrice,
                [this.styleClasses.mainPriceLineThroughStyle]: false,
                [this.styleClasses.mainPriceHighlightActionStyle]: (this.isSelloutPriceHigherOrEvenThanUserPrice || this.isPriceLowerThanBasePrice) && this.product.IsDifferentPriceListThanDefault,
            };
            this.priceStyleClasses.commonPriceClasses = {
                [this.styleClasses.commonPriceStyle]: true,
                [this.styleClasses.commonPriceHighlightStyle]: this.isOnlyActionCondition && this.renderStyle === this.renderStyles.TableDPH,
                [this.styleClasses.commonPriceHighlightActionStyle]: false
            };
        }
    }
}